import './index.css'
import { createApp } from 'vue'
import router from './router'
import App from './App.vue'
import ToastPlugin from 'vue-toast-notification';

import 'vue-loading-overlay/dist/css/index.css';
import 'vue-toast-notification/dist/theme-bootstrap.css';
import 'aos/dist/aos.css'

import { usersStore } from './store/user'

import { Button, setConfig, frappeRequest, resourcesPlugin } from 'frappe-ui'
import { createPinia } from 'pinia'


import Loader from './components/PageLoader.vue'; // Adjust the path

let pinia = createPinia()

let app = createApp(App)

setConfig('resourceFetcher', frappeRequest)

app.use(pinia)
app.use(router)
app.use(resourcesPlugin)

app.component('Button', Button)
app.component('Loader', Loader)
app.use(ToastPlugin);
app.mount('#app')

const { userResource, allUsers } = usersStore()
// console.log(userResource)
app.provide('$user', userResource)
app.provide('$allUsers', allUsers)
app.config.globalProperties.$user = userResource