<template>
	<div
		v-if="show"
		class="fixed top-0 right-0 left-0 z-50 flex justify-center items-center w-full h-[calc(100%)] max-h-full"
	>
		<div
			v-if="show"
			@click.self="show = false"
			class="absolute inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
		></div>
		<div class="relative w-full max-w-2xl max-h-[85vh] overflow-y-auto rounded-lg">
			<!-- Modal content -->
			<div class="relative bg-white shadow dark:bg-gray-700">
				<!-- Modal header -->
				<div
					class="flex items-center justify-between p-4 border-b rounded-t dark:border-gray-600"
				>
					<h3 class="text-lg font-semibold text-gray-900 dark:text-white">
						Report a Problem
					</h3>
					<button
						type="button"
						class="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm w-8 h-8 inline-flex justify-center items-center dark:hover:bg-gray-600 dark:hover:text-white"
						@click="show = false"
					>
						<svg
							class="w-3 h-3"
							aria-hidden="true"
							xmlns="http://www.w3.org/2000/svg"
							fill="none"
							viewBox="0 0 14 14"
						>
							<path
								stroke="currentColor"
								stroke-linecap="round"
								stroke-linejoin="round"
								stroke-width="2"
								d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
							/>
						</svg>
						<span class="sr-only">Close modal</span>
					</button>
				</div>

				<!-- Modal body -->
				<div class="isolate p-4 md:p-5">
					<div class="grid gap-4 mb-4 grid-cols-2">
						<div class="col-span-2">
							<label
								for="subject"
								class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
								>Subject</label
							>
							<input
								v-model="report.subject"
								type="text"
								name="subject"
								id="subject"
								class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
								placeholder="Enter report subject"
								required=""
							/>
						</div>
						<div class="col-span-2">
							<label
								for="message"
								class="block mb-2 text-sm font-medium text-gray-900 dark:text-white"
								>Message</label
							>
							<textarea
								v-model="report.message"
								name="message"
								id="message"
								rows="4"
								class="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary-500 dark:focus:border-primary-500"
								placeholder="Describe the issue"
								required=""
							></textarea>
						</div>
					</div>

					<div class="mt-10">
						<button
							class="align-middle select-none rounded-lg font-medium text-cente transition-all  disabled:shadow-none disabled:pointer-events-none py-2 px-6 bg-transparent text-black shadow-md shadow-trabg-transparent/10 hover:shadow-lg hover:shadow-trabg-transparent/20 focus:opacity-[0.85] focus:shadow-none active:opacity-[0.85] active:shadow-none capitalize w-full"
							type="button"
							@click="sendReport"
							:disabled="isLoading"
                            style="
								background: linear-gradient(236deg, #42B8FE -20.75%, #09EAFE 108.83%);
							"
						>
							Send Report
						</button>
					</div>
				</div>
			</div>
		</div>
	</div>
    <Loader :isLoading="isLoading" />
</template>
<script setup>
import { ref, reactive, watch } from 'vue'
import { showToast } from '@/utils'
import { createResource } from 'frappe-ui'


const isLoading = ref(false)
const showSpinner = ref(false)

const emit = defineEmits(['update:showModal'])
const props = defineProps({
	showModal: Boolean,
	user: {
		type: Object,
		required: true,
	},
})

const show = ref(false)
watch(
    () => props.showModal,
    (newVal) => {
        show.value = newVal
        if (!newVal) {
            // Clear fields when modal is closed
            report.subject = ''
            report.message = ''
        }
    }
)

watch(show, (newVal) => {
	emit('update:showModal', newVal)
})

// Reactive data for the report
const report = reactive({
	subject: '',
	message: '',
})

const sendReportResource = createResource({
	url: 'rebuid.rebuid.utils.send_report',
	makeParams(values) {
		return {
            subject: report.subject,
            message: report.message,
		}
	},
})
// 
const sendReport = () => {
    // apply validation report
    if (!report.subject) {
        showToast('error', 'Subject is required', 'x')
        return
    }
    if (!report.message) {
        showToast('error', 'Message is required', 'x')
        return
    }

	isLoading.value = true // Show loader before submission
	showSpinner.value = true
	sendReportResource.submit(
		{},
		{
			onSuccess(data) {
				isLoading.value = false // Hide loader on success
				showSpinner.value = false
				if (data.status === 'error') {
					showToast('error', data.message, 'x')
					return
				}

				show.value = false
				showToast('success', 'Report sent successfully', 'x')
			},
			onError(err) {
				isLoading.value = false // Hide loader on error
				showSpinner.value = false
				showToast('error', err.messages?.[0] || err, 'x')
				show.value = false
			},
		},
	)
}

</script>
